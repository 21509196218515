import { Outlet } from 'react-router-dom'

import { CommonFooter } from '../Common/Footer'
import { useLocales } from '../../locales'
import { useHeaderContext } from '../../hooks/modals/global-set-header'
import { CommonHeader } from '../Common/Header'
import { useEffect, useState } from 'react'

type Props = {
  hasbgcolor?: boolean
  headerAbsolute?: boolean
}

export const SessionLayoutWrapper = ({ hasbgcolor = false, headerAbsolute = false }: Props) => {
  const feedbackUrl = import.meta.env.VITE_FEEDBACK_URL

  const { t } = useLocales()
  const { showHeader, isHeaderAbsolute } = useHeaderContext()

  const [absolute, setAbsolute] = useState(headerAbsolute)

  useEffect(() => {
    setAbsolute(isHeaderAbsolute)
  }, [isHeaderAbsolute])

  return (
    <div className={`w-full ${hasbgcolor ? 'bg-gradient-to-b from-[rgba(237,37,128,0.8)] to-[rgba(242,110,87,0.8)]' : ''}`}>
      <div className="mx-auto h-full max-h-max min-h-screen w-full min-w-[375px] max-w-[895px] bg-white font-proxima-nova">
        <a href={feedbackUrl} className="fixed right-5 top-5 z-50 cursor-pointer rounded-full bg-[#D42128] bg-opacity-80 px-4 py-2 font-semibold text-white hover:text-pink-200">
          {t('common.feedback')}
        </a>

        {showHeader && <CommonHeader className={absolute ? 'z-40' : 'relative z-40 bg-white'} isLogoWhite={headerAbsolute} />}

        <div className={`h-full min-h-screen w-full bg-white pb-[58px] text-black ${showHeader ? 'mt-[74px] min-h-screen ' : 'min-h-[calc(100vh-74px)]'} `}>
          <Outlet />
        </div>

        <CommonFooter />
      </div>
    </div>
  )
}
