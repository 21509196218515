import logoDefaultPath from '../../assets/images/logo.png'
import logoWhitePath from '../../assets/images/logo-white.svg'

type Props = {
  className: string
  isLogoWhite?: boolean
}

export const Logo = ({ className, isLogoWhite = false }: Props) => {
  return <img src={isLogoWhite ? logoWhitePath : logoDefaultPath} className={`${`${className} bg-contain bg-no-repeat transition-opacity duration-300`} `} alt="My Adesso Logo" />
}
